import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function Nafath() {
  const [data, setData] = useState({});

  const getNafithSanad = useSelector((state) => state.getNafithSanad);

  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ID = queryParams.get("id");

  function GetNafathDetail() {
    dispatch({
      type: "GET_NAFITH_SANAD",
      payload: ID,
    });
  }

  useEffect(() => {
    GetNafathDetail();
  }, []);

  useEffect(() => {
    if (getNafithSanad?.body?.data) {
      setData(getNafithSanad?.body?.data);
    }
  }, [getNafithSanad?.body?.data]);

  return (
    <div className="w-full">
      <div className="w-full flex justify-center">
        {data ? (
          <div className="w-full overflow-x-auto">
            <table className="w-full border border-gray-400 text-left text-sm md:text-md table-auto">
              <thead>
                <tr className="bg-gray-200 text-gray-700">
                  <th className="px-4 py-2 border">Field</th>
                  <th className="px-4 py-2 border">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    Creditor National Id
                  </td>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    {data?.creditorNationalId || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    Currency
                  </td>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    {data?.currency || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    Debtor National Id
                  </td>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    {data?.debtorNationalId || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    Max Approve Duration
                  </td>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    {data?.maxApproveDuration || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    Nafith Group Id
                  </td>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    {data?.nafithGroupId || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    Number Of Sanads
                  </td>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    {data?.numberOfSanads || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    Reference Id
                  </td>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    {data?.referenceId || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border whitespace-nowrap">Status</td>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    {data?.status || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    Total Value
                  </td>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    {data?.totalValue || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    Request Payload
                  </td>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    {data?.nafithRequest ? (
                      <div className="space-y-2">
                        {/* Parse and display individual fields */}
                        {(() => {
                          const nafithRequest = JSON.parse(data.nafithRequest);
                          return (
                            <div>
                              <p>
                                <strong>Debtor National Id:</strong>{" "}
                                {nafithRequest?.debtor?.national_id || "N/A"}
                              </p>
                              <p>
                                <strong>Currency:</strong>{" "}
                                {nafithRequest?.currency || "N/A"}
                              </p>
                              <p>
                                <strong>Max Approve Duration:</strong>{" "}
                                {nafithRequest?.max_approve_duration || "N/A"}
                              </p>
                              <p>
                                <strong>City of Issuance:</strong>{" "}
                                {nafithRequest?.city_of_issuance || "N/A"}
                              </p>
                              <p>
                                <strong>City of Payment:</strong>{" "}
                                {nafithRequest?.city_of_payment || "N/A"}
                              </p>
                              <p>
                                <strong>Debtor Phone Number:</strong>{" "}
                                {nafithRequest?.debtor_phone_number || "N/A"}
                              </p>
                              <p>
                                <strong>Total Value:</strong>{" "}
                                {nafithRequest?.total_value || "N/A"}
                              </p>
                              <p>
                                <strong>Country of Issuance:</strong>{" "}
                                {nafithRequest?.country_of_issuance || "N/A"}
                              </p>
                              <p>
                                <strong>Country of Payment:</strong>{" "}
                                {nafithRequest?.country_of_payment || "N/A"}
                              </p>
                              {/* Map Sanad Details */}
                              {nafithRequest?.sanad?.map((sanad, index) => (
                                <div key={index} className="mt-2">
                                  <p>
                                    <strong>Sanad {index + 1} Due Type:</strong>{" "}
                                    {sanad.due_type || "N/A"}
                                  </p>
                                  <p>
                                    <strong>Sanad {index + 1} Due Date:</strong>{" "}
                                    {sanad.due_date || "N/A"}
                                  </p>
                                  <p>
                                    <strong>
                                      Sanad {index + 1} Total Value:
                                    </strong>{" "}
                                    {sanad.total_value || "N/A"}
                                  </p>
                                  <p>
                                    <strong>
                                      Sanad {index + 1} Reference Id:
                                    </strong>{" "}
                                    {sanad.reference_id || "N/A"}
                                  </p>
                                </div>
                              ))}
                            </div>
                          );
                        })()}
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </td>
                </tr>

                <tr>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    Response Payload
                  </td>
                  <td className="px-4 py-2 border whitespace-nowrap">
                    {data?.nafithResponse ? (
                      <div className="space-y-2">
                        {/* Parse and display individual fields */}
                        {(() => {
                          const nafithResponse = JSON.parse(
                            data.nafithResponse
                          );
                          return (
                            <div>
                              <p>
                                <strong>Response ID:</strong>{" "}
                                {nafithResponse?.id || "N/A"}
                              </p>
                              <p>
                                <strong>Type:</strong>{" "}
                                {nafithResponse?.type || "N/A"}
                              </p>
                              <p>
                                <strong>Currency:</strong>{" "}
                                {nafithResponse?.currency || "N/A"}
                              </p>
                              <p>
                                <strong>Status:</strong>{" "}
                                {nafithResponse?.status || "N/A"}
                              </p>
                              <p>
                                <strong>Code:</strong>{" "}
                                {nafithResponse?.code || "N/A"}
                              </p>

                              {/* Creditor Details */}
                              <div>
                                <p>
                                  <strong>Creditor National ID:</strong>{" "}
                                  {nafithResponse?.creditor?.national_id ||
                                    "N/A"}
                                </p>
                                <p>
                                  <strong>Creditor Name:</strong>{" "}
                                  {nafithResponse?.creditor?.last_name || "N/A"}
                                </p>
                                <p>
                                  <strong>Creditor Phone:</strong>{" "}
                                  {nafithResponse?.creditor?.phone_number ||
                                    "N/A"}
                                </p>
                              </div>

                              {/* Debtor Details */}
                              <div>
                                <p>
                                  <strong>Debtor National ID:</strong>{" "}
                                  {nafithResponse?.debtor?.national_id || "N/A"}
                                </p>
                                <p>
                                  <strong>Debtor Phone:</strong>{" "}
                                  {nafithResponse?.debtor_phone_number || "N/A"}
                                </p>
                              </div>

                              <p>
                                <strong>Country of Issuance:</strong>{" "}
                                {nafithResponse?.country_of_issuance || "N/A"}
                              </p>
                              <p>
                                <strong>City of Issuance:</strong>{" "}
                                {nafithResponse?.city_of_issuance || "N/A"}
                              </p>
                              <p>
                                <strong>Issued At:</strong>{" "}
                                {nafithResponse?.issued_at || "N/A"}
                              </p>
                              <p>
                                <strong>Country of Payment:</strong>{" "}
                                {nafithResponse?.country_of_payment || "N/A"}
                              </p>
                              <p>
                                <strong>City of Payment:</strong>{" "}
                                {nafithResponse?.city_of_payment || "N/A"}
                              </p>
                              <p>
                                <strong>Reference ID:</strong>{" "}
                                {nafithResponse?.reference_id || "N/A"}
                              </p>
                              <p>
                                <strong>Number of Sanads:</strong>{" "}
                                {nafithResponse?.number_of_sanads || "N/A"}
                              </p>
                              <p>
                                <strong>Total Value:</strong>{" "}
                                {nafithResponse?.total_value || "N/A"}
                              </p>
                              <p>
                                <strong>Max Approve Duration:</strong>{" "}
                                {nafithResponse?.max_approve_duration || "N/A"}
                              </p>

                              {/* Sanad Details */}
                              {nafithResponse?.sanad?.map((sanad, index) => (
                                <div key={index} className="mt-2">
                                  <p>
                                    <strong>Sanad {index + 1} Status:</strong>{" "}
                                    {sanad.status || "N/A"}
                                  </p>
                                  <p>
                                    <strong>
                                      Sanad {index + 1} Created At:
                                    </strong>{" "}
                                    {sanad.created_at || "N/A"}
                                  </p>
                                  <p>
                                    <strong>
                                      Sanad {index + 1} Updated At:
                                    </strong>{" "}
                                    {sanad.updated_at || "N/A"}
                                  </p>
                                  <p>
                                    <strong>Sanad {index + 1} Due Type:</strong>{" "}
                                    {sanad.due_type || "N/A"}
                                  </p>
                                  <p>
                                    <strong>
                                      Sanad {index + 1} Total Value:
                                    </strong>{" "}
                                    {sanad.total_value || "N/A"}
                                  </p>
                                  <p>
                                    <strong>
                                      Sanad {index + 1} Reference ID:
                                    </strong>{" "}
                                    {sanad.reference_id || "N/A"}
                                  </p>
                                </div>
                              ))}
                            </div>
                          );
                        })()}
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="w-full text-center text-gray-700">No Data Found!</div>
        )}
      </div>
    </div>
  );
}

export default Nafath;
