import React, { useState } from "react";
import EmiDetail from "./tabs/EmiDetail";
import LoanHistory from "./tabs/LoanHistory";
import TransactionHistory from "./tabs/transactionHistory";
import SeelaInvestment from "./tabs/seelaInvestment";
import SelaSellRadeem from "./tabs/SellRadeemSelaa";
import ApplicationStatusTracking from "./tabs/applicationStatusTracking";
import { useTranslation } from "react-i18next";
import SimahReport from "./tabs/simahReport";
function Template() {
  const [state, setState] = useState("Simah Report");
  const { t } = useTranslation();

  const getTab = () => {
    const activeItem = data.find((item) => item.label === state);
    return activeItem ? activeItem.tab : null;
  };

  function setNavigation(stateValue) {
    setState(stateValue);
  }

  return (
    <div className="bg-white  dark:bg-dark2 border border-primary w-full rounded-lg  ">
      <div className="flex flex-row border-b border-gray-300 dark:border-dark1 overflow-x-scroll ">
        {data?.map((v, k) => {
          return (
            <div
              onClick={() => setNavigation(v.label)}
              className={`px-2 cursor-pointer ${
                state === v.label
                  ? "text-primary "
                  : "text-gray-600 dark:text-dark0 "
              }`}
            >
              <div
                className={`px-2 py-4  w-max  ${
                  state === v.label ? "border-primary border-b-2" : " "
                }`}
              >
                <a className="text-sm">{t(v.label)}</a>
              </div>
            </div>
          );
        })}
      </div>

      <div className="  rtl:space-x-reverse  w-full px-4 flex flex-col justify-center rounded-lg  overflow-scroll ">
        <div className="flex flex-row space-x-5  w-full whitespace-nowrap">
          {getTab()}
        </div>
      </div>
    </div>
  );
}
export default Template;

const data = [
  {
    label: "Simah Report",
    tab: <SimahReport />,
  },
  {
    label: "Emi Detail",
    tab: <EmiDetail />,
  },
  {
    label: "Loan History",
    tab: <LoanHistory />,
  },
  {
    label: "Bank Transaction History",
    tab: <TransactionHistory />,
  },
  {
    label: "Application Status Tracking",
    tab: <ApplicationStatusTracking />,
  },
  {
    label: "Selaa Investment Transaction",
    tab: <SeelaInvestment />,
  },
  {
    label: "Selaa Sell and Redeem Transactions",
    tab: <SelaSellRadeem />,
  },
];
