import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function SimahReport() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const UserId = queryParams.get("user");

  return (
    <div className="p-1 w-full py-10 ">
      <div
        onClick={() =>
          navigate(`/customers/verified/simah/usercodes?id=${UserId}`)
        }
        className={` w-max  mx-5 text-white bg-blue-500 hover:opacity-80 duration-200 cursor-pointer border-blue-400 border px-7 py-2 rounded-md  items-center flex flex-col   `}
      >
        <div className=" text-sm font-semibold ">View Simah Report</div>
      </div>
    </div>
  );
}
export default SimahReport;
