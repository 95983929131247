import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

function Nafith() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const message = useSelector((state) => state.message);

  const report = useSelector((state) => state.getNafith);
  useEffect(() => {
    GetNafithResponse();
  }, []);
  function GetNafithResponse() {
    dispatch({
      type: "GET_NAFITH_REPORT",
      payload: id,
    });
  }

  const [pdfURL, setPdfURL] = useState("");
  console.log("report report report report url url ", pdfURL);
  useEffect(() => {
    if (report) {
      try {
        console.log("Processing Base64 report data...");

        // Decode the Base64 string into binary data
        const byteCharacters = atob(report);
        const byteNumbers = Array.from(byteCharacters, (char) =>
          char.charCodeAt(0)
        );
        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from the binary data
        const blob = new Blob([byteArray], { type: "application/pdf" });

        // Generate a URL for the Blob
        const url = URL.createObjectURL(blob);
        setPdfURL(url);

        // Cleanup the Blob URL when the component unmounts
        return () => {
          URL.revokeObjectURL(url);
        };
      } catch (error) {
        console.error("Error processing Base64 report:", error);
      }
    }
  }, [report]);

  useEffect(() => {
    console.log("message==========>", message);
  }, [message]);
  return (
    <div
      className={`w-full flex flex-row space-x-5 rtl:space-x-reverse ${
        !message && `py-10`
      }`}
    >
      {pdfURL ? (
        <div className="w-full ">
          <iframe
            title="PDF Viewer"
            src={pdfURL}
            width="100%"
            height="1200px"
            frameBorder="0"
          ></iframe>
        </div>
      ) : !message ? (
        <div className="items-center text-center  w-full">Loading ...</div>
      ) : message ? (
        <div className="items-center text-center py-10 w-full">{message}</div>
      ) : (
        ""
      )}
    </div>
  );
}
export default Nafith;
