import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Model } from "Components";
import Model2 from "Components/Model2";
import withAuthorization from "constants/authorization";
import CreateBankCode from "./Model/CreateBankCode";
import {
  AsyncCountries,
  DeleteCountryFromBlackList,
  DeleteSeulahBankCode,
  GetSeulahBankCodes,
} from "Services/OtherApis";
import * as action from "Services/redux/reducer";
import { useTranslation } from "react-i18next";
import Table from "Components/Table";

function BlackListCountries() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modelOpen, setModelOpen] = useState(false);
  const [modelOpen2, setModelOpen2] = useState(false);
  const [activeDelete, setActiveDelete] = useState();
  const [data, setData] = useState([]);
  console.log("activeDelete", activeDelete);

  function reset() {
    setModelOpen(false);
  }

  useEffect(() => {
    getList();
  }, []);
  function getList() {
    GetSeulahBankCodes().then((data) => {
      if (!data?.error) {
        setData(data?.response?.data);
      }
    });
  }
  function DeleteCountry(id) {
    DeleteSeulahBankCode(activeDelete).then((res) => {
      if (res.status === 200) {
        dispatch(
          action.Message({
            message: res?.data || "Success",
            open: true,
            error: false,
          })
        );
        setModelOpen2(false);
        getList();
      } else {
        dispatch(
          action.Message({
            message: "Server Error",
            open: true,
            error: true,
          })
        );
      }
    });
  }

  let header = [
    { name: "Bank Name English" },
    { name: "Bank Name Arabic" },
    { name: "Bic Code" },
    { name: "Action" },
  ];
  return (
    <div className="  bg-white dark:bg-dark2 border border-primary w-full rounded-lg mt-4 md:mt-0 p-4">
      <div className="flex flex-row  overflow-x-auto  justify-between items-center">
        <h1 className="dark:text-dark0">{t("Bank Codes")}</h1>
        <div className="space-x-4 rtl:space-x-reverse">
          <Button
            onButtonClick={() => setModelOpen(true)}
            buttonValue={t("Add Bank Code")}
            buttonStyle="px-20 py-2"
          />
        </div>
      </div>

      <div className="  w-full">
        <div className="overflow-x-auto relative">
          <Table header={header} styleMain={"mt-0"}>
            <tbody className="dark:text-dark0">
              {data?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  <td className="px-3 py-4">{v?.bankNameEnglish}</td>
                  <td className="px-3 py-4 amiri-regular">
                    {v?.bankNameArabic}
                  </td>
                  <td className="px-3 py-4">{v?.bicCode}</td>

                  <td
                    className="px-3 py-4"
                    onClick={() => (
                      setModelOpen2(true), setActiveDelete(v?.id)
                    )}
                  >
                    <div className="bg-red-500 rounded-md px-4 py-1 text-white text-center items-center w-min hover:bg-opacity-80 cursor-pointer duration-300">
                      {t("Delete")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {modelOpen ? (
        <Model2
          setModelOpen={(e) => setModelOpen(e)}
          reset={() => reset()}
          heading={t("New Seulah Bank Code")}
        >
          <CreateBankCode
            setModelOpen={(e) => setModelOpen(e)}
            getBlackListCountries={() => getList()}
          />
        </Model2>
      ) : null}

      <Model
        heading={t("Delete Country")}
        isOpen={modelOpen2}
        style="w-1/3"
        innerStyle="py-10"
        setState={() => setModelOpen2(!modelOpen2)}
        action1Value={t("Cancel")}
        action2Value={t("Delete")}
        action2={() => DeleteCountry()}
        action1={() => setModelOpen2(!modelOpen)}
      >
        <a className=" text-xl text-gray-800 dark:text-white">
          {t("Are you sure to delete this ?")}
          <span className="font-semibold"> </span>
        </a>
      </Model>
    </div>
  );
}

export default withAuthorization(BlackListCountries);
