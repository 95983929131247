import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as action from "Services/redux/reducer";
import { IoMdCheckmark } from "react-icons/io";
import { GetUserApplicationStatusTracking } from "Services/OtherApis";
import { RxCross2 } from "react-icons/rx";
import Table from "Components/Table";

function ApplicationStatusTracking() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const UserId = queryParams.get("user");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  function getDetails() {
    setLoading(true);
    GetUserApplicationStatusTracking(UserId)
      .then((data) => {
        setData(data?.response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(action.Message({ open: true, message: "Error", error: true }));
      });
  }

  return (
    <div className=" w-full pb-10">
      {data?.length > 0 ? (
        <div className="overflow-x-auto relative  ">
          <Table header={header} styleMain={"mt-0"}>
            <tbody className="dark:text-dark0">
              {data?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  <td scope="row" className="px-3 py-4">
                    {v?.name}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.value ? (
                      <IoMdCheckmark className="text-xl text-green-500" />
                    ) : (
                      <RxCross2 className="text-xl text-red-500" />
                    )}
                  </td>
                  <td
                    scope="row"
                    className=" px-3 py-4 amiri-regular max-w-[200px] whitespace-normal break-words"
                  >
                    {v?.reasonArabic}
                  </td>
                  <td
                    scope="row"
                    className=" px-3 py-4 max-w-[200px] whitespace-normal break-words"
                  >
                    {v?.reasonEnglish}
                  </td>

                  <td scope="row" className="px-3 py-4">
                    {v?.createdDate}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.modifiedDate}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="  items-center dark:text-dark0 text-center mt-5 text-lg py-5 text-gray-600">
          {data?.length === 0 && loading ? (
            "Loading ..."
          ) : (
            <a className="text-xl dark:text-dark0">No data found !</a>
          )}
        </div>
      )}
    </div>
  );
}
export default ApplicationStatusTracking;

let header = [
  { name: "Operation Name" },
  { name: "Value" },
  { name: "Reason Arabic" },
  { name: "Reason English" },
  { name: "Created Date" },
  { name: "Modified Date" },
];
