import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Button } from "Components";
import { useTranslation } from "react-i18next";
import * as action from "Services/redux/reducer";
import { AddNewSeulahBankCodes } from "Services/OtherApis";

function CreateBankCodes({ setModelOpen, getBlackListCountries }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Validation schema
  const validationSchema = Yup.object().shape({
    nameEnglish: Yup.string().required(t("This field is required")),
    nameArabic: Yup.string().required(t("This field is required")),
    code: Yup.string().required(t("This field is required")),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      nameEnglish: "",
      nameArabic: "",
      code: "",
    },
  });

  const onSubmit = (data) => {
    AddCodesSeulah(data);
  };

  const AddCodesSeulah = (data) => {
    const temp = {
      bankNameArabic: data?.nameArabic,
      bankNameEnglish: data?.nameEnglish,
      bicCode: data?.code,
    };
    AddNewSeulahBankCodes(temp).then((res) => {
      if (res?.status === 200) {
        dispatch(
          action.Message({
            message: res?.data || "Success",
            open: true,
            error: false,
          })
        );
        getBlackListCountries();
        setModelOpen(false);
        reset();
      } else {
        dispatch(
          action.Message({
            message: "Error",
            open: true,
            error: true,
          })
        );
      }
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="items-center justify-center flex flex-col"
    >
      <div className="bg-white dark:bg-dark2 rounded shadow-sm rtl:space-x-reverse flex flex-col lg:flex-row lg:w-max lg:space-x-20 lg:px-8 px-4 py-5">
        <div className="flex flex-col">
          <div className="flex flex-col mt-5 w-[400px] space-y-3">
            <InputField
              control={control}
              name="nameEnglish"
              heading={t("Bank Name English")}
              error={errors.nameEnglish?.message}
            />
            <InputField
              control={control}
              name="nameArabic"
              heading={t("Bank Name Arabic")}
              error={errors.nameArabic?.message}
            />
            <InputField
              control={control}
              name="code"
              heading={t("Bank Bic Code")}
              error={errors.code?.message}
            />
          </div>

          <div className="flex flex-row justify-end mt-10 mb-5 px-14 w-full">
            <Button
              type="submit"
              buttonValue={t("Submit")}
              buttonStyle="px-20 py-2 w-full"
              disabled={!isValid}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default CreateBankCodes;

function InputField({ control, name, heading, error, type = "text" }) {
  return (
    <div className="flex flex-col w-full">
      <label className="text-sm text-gray-700 dark:text-dark0">{heading}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <input
            {...field}
            type={type}
            className={`border-gray-300 border rounded-md px-3 py-2 outline-none mt-2 dark:bg-dark1 dark:text-dark0 dark:border-dark3 ${
              error ? "border-red-500" : ""
            }`}
          />
        )}
      />
      {error && <span className="text-red-500 text-sm mt-1">{error}</span>}
    </div>
  );
}
