import React from "react";
import { useTranslation } from "react-i18next";
import CardMain from "../../../Components/Cards/main";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import withAuthorization from "../../../constants/authorization";
import Table from "Components/Table";

function AllUsers() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector((state) => state.getSeelahTransaction);
  useEffect(() => {
    getAllUsersData();
  }, []);
  function getAllUsersData() {
    dispatch({
      type: "GET_SELAA_TRANSACTION",
    });
  }

  let header = [
    { name: "Amount" },
    { name: "Lended Id" },
    { name: "Type" },
    { name: "Wallet Name" },
    { name: "Active" },
    { name: "Certificates" },
    { name: "Lender Id" },
    { name: "Lender Internal Id" },
    { name: "lenders Customer Id" },
    { name: "owner" },
    { name: "ownership Id" },
    { name: "redeem Allowed" },
    { name: "Wallet" },
    { name: "File" },
  ];
  function formatNumber(value) {
    if (isNaN(value)) return "0.00";
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return (
    <div className="py-5">
      <CardMain
        width="w-full"
        heading={t("Customer Transaction")}
        iconStyle="text-3xl text-primary"
      >
        <div className="overflow-x-scroll relative h-[70vh]">
          <Table header={header}>
            <tbody className=" ">
              {data?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  <td
                    scope="row"
                    className="px-3 py-3 flex flex-row space-x-3 items-center rtl:space-x-reverse"
                  >
                    <a>{formatNumber(v?.amount)}</a>
                  </td>
                  <td className="px-3">{v?.lenderId}</td>
                  <td className="px-3">{v?.type}</td>
                  <td className="px-3">{v?.walletName || "null"}</td>
                  <td className="px-3">
                    {v?.transaction?.active ? "true" : "false"}
                  </td>
                  <td className="px-3">{v?.certificates}</td>

                  <td className="px-3">{v?.lenderId}</td>
                  <td className="px-3">{v?.lenderInternalId}</td>
                  <td className="px-3">{v?.lendersCustomerId || "null"}</td>
                  <td className="px-3">{v?.owner}</td>
                  <td className="px-3">{v?.ownershipId}</td>
                  <td className="px-3">
                    {v?.redeemAllowed ? "true" : "false"}
                  </td>
                  <td className="px-3">{formatNumber(v?.wallet)}</td>
                  <td className="px-3">
                    <div
                      onClick={() =>
                        window.open(v?.ownershipFileUrl, "_blank", "noopener")
                      }
                      className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                    >
                      {t("Owner Ship File")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardMain>
    </div>
  );
}
export default withAuthorization(AllUsers);
