import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as action from "Services/redux/reducer";
import { GetSelaaSellRadeemValues } from "Services/OtherApis";
import Table from "Components/Table";
import Model from "Components/Model2";

function SelaSellRadeem() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState([]);

  const [modelOpen, setModelOpen] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const UserId = queryParams.get("user");

  const [activeData, setActiveData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetSelaaSellRadeem();
  }, []);

  function GetSelaaSellRadeem() {
    setLoading(true);
    GetSelaaSellRadeemValues(UserId)
      .then((data) => {
        // dispatch(
        //   action.Message({ open: true, message: "Success", error: false })
        // );

        setData(data?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(action.Message({ open: true, message: "Error", error: true }));
      });
  }

  let header = [
    { name: "Transfer Owner ShipId" },
    { name: "Buy Owner ShipId" },
    { name: "Application Id" },
    { name: "Certificates" },
    { name: "Transfer Status" },
    { name: "Radeem Status" },
    { name: "Transfer Ownership File" },
    { name: "Radeem Ownership File" },
    { name: "Transfer Request" },
    { name: "Transfer Response" },
    { name: "Radeem Request" },
    { name: "Radeem Response" },
  ];

  return (
    <div className="p-1 w-full pb-10 ">
      {data?.length > 0 ? (
        <div className="overflow-x-auto relative  ">
          <Table header={header} styleMain={"mt-0"}>
            <tbody className="dark:text-dark0">
              {data?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  <td scope="row" className="px-3 py-4">
                    {v?.ownershipId}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.buyOwnershipId}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.applicationId}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.certificates}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.transferStatus}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.redeemStatus}
                  </td>

                  <td className="px-3">
                    {v?.transferOwnershipFileUrl ? (
                      <div
                        onClick={() =>
                          window.open(
                            v?.transferOwnershipFileUrl,
                            "_blank",
                            "noopener"
                          )
                        }
                        className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                      >
                        {t("Transfer Ownership File")}
                      </div>
                    ) : (
                      "Not Found"
                    )}
                  </td>
                  <td className="px-3">
                    {v?.redeemOwnershipFileUrl ? (
                      <div
                        onClick={() =>
                          window.open(
                            v?.redeemOwnershipFileUrl,
                            "_blank",
                            "noopener"
                          )
                        }
                        className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                      >
                        {t("Radeem Ownership File")}
                      </div>
                    ) : (
                      "Not Found"
                    )}
                  </td>
                  {/* 
                  <td
                    scope="row"
                    className="px-3 py-4 max-w-[200px] whitespace-normal break-words"
                  >
                    {v?.transferRequest}
                  </td> */}

                  <td className="px-3">
                    <div
                      className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                      onClick={() => (
                        setActiveData(v?.transferRequest), setModelOpen(true)
                      )}
                    >
                      {t("Transfer Request")}
                    </div>
                  </td>
                  <td className="px-3">
                    <div
                      className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                      onClick={() => (
                        setActiveData(v?.transferResponse), setModelOpen(true)
                      )}
                    >
                      {t("Transfer Response")}
                    </div>
                  </td>
                  <td className="px-3">
                    <div
                      className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                      onClick={() => (
                        setActiveData(v?.redeemRequest), setModelOpen(true)
                      )}
                    >
                      {t("Radeem Request")}
                    </div>
                  </td>
                  <td className="px-3">
                    <div
                      className="w-max px-3 py-1 rounded-md text-white bg-green-500 cursor-pointer hover:opacity-80 duration-200"
                      onClick={() => (
                        setActiveData(v?.redeemResponse), setModelOpen(true)
                      )}
                    >
                      {t("Radeem Response")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="  items-center dark:text-dark0 text-center mt-5 text-lg py-5 text-gray-600">
          {data?.length === 0 && loading ? (
            "Loading ..."
          ) : (
            <a className="text-xl dark:text-dark0">No data found !</a>
          )}
        </div>
      )}

      {modelOpen ? (
        <Model
          setModelOpen={(e) => setModelOpen(e)}
          reset={() => (setModelOpen(false), setActiveData(""))}
          heading="Request Content"
        >
          <div className="px-5 py-4 flex flex-row pb-7">
            <div className="min-w-[400px] max-w-[500px] space-y-4 flex flex-col opacity-80 whitespace-normal break-words text-whiet dark:text-dark0">
              {activeData}
            </div>
          </div>
        </Model>
      ) : null}
    </div>
  );
}
export default SelaSellRadeem;
