import React from "react";
import { useTranslation } from "react-i18next";
import CardMain from "../../../Components/Cards/main";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import withAuthorization from "../../../constants/authorization";
import Table from "Components/Table";

function VerifiedUsers() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getAllUsersEmi = useSelector((state) => state.getAllUsersEmi);

  useEffect(() => {
    getAllUsersEmiData();
  }, []);
  function getAllUsersEmiData() {
    dispatch({
      type: "GET_ALL_USERS_EMI",
    });
  }
  let header = [
    { name: "User Id" },
    { name: "Gross Salary" },
    { name: "Include Mtg" },
    { name: "Net Income" },
    { name: "Simah Liabilities" },
    { name: "Total Dependents" },
    { name: "Final Eligible EMI" },
    { name: "Domestic Worker" },
    { name: "Disposable Income" },
    { name: "Children" },
  ];

  function formatNumber(value) {
    if (isNaN(value)) return "0.00";
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return (
    <div className="py-5">
      <CardMain
        width="w-full"
        heading={t("Customers EMI")}
        iconStyle="text-3xl text-primary"
      >
        <div className="overflow-x-auto relative ">
          <Table header={header}>
            <tbody className="dark:text-dark0">
              {getAllUsersEmi?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  <td scope="row" className="px-3 py-3">
                    {v?.userId}
                  </td>
                  <td scope="row" className="px-3 py-3">
                    {formatNumber(v?.grossSalary) || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-3">
                    {v?.includeMtg === true ? "True" : "False"}
                  </td>
                  <td scope="row" className="px-3 py-3">
                    {formatNumber(v?.netIncome) || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-3">
                    {v?.simahLibilities || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-3">
                    {v?.totalDependents || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-3">
                    {formatNumber(v?.finalEligibleEMI) || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-3">
                    {v?.domesticWorker || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-3">
                    {formatNumber(v?.disposableIncome) || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-3">
                    {v?.children || "NAN"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardMain>
    </div>
  );
}

export default withAuthorization(VerifiedUsers);
