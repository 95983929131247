import React from "react";
import { TiContacts } from "react-icons/ti";
import { useTranslation } from "react-i18next";
function Disclaimer({ w1, w2, data }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row border border-sky-700 w-full mt-5 text-white">
      <div
        className={`py-10 px-3  bg-sky-700 items-center  flex flex-col ${w1}`}
      >
        <TiContacts className="text-5xl" />

        <a className="text-sm mt-4">{t("Disclaimer and Disclosure")}</a>
      </div>
      <div className={`${w2} px-2 space-y-2 flex flex-col`}>
        <a className="text-gray-700  py-1 text-start  ">
          <span className="font-semibold"></span>
          {data?.discTextDescEn && (
            <>
              {t(
                "Disclaimer: This information has been collated from various sources on a confidential basis and doesnt represent the opinion of Saudi Credit Bureau (SIMAH). No Liability (in contract or otherwise whatsoever) attaches to SIMAH as a result of taking any investment and/or any other decision based on information provided. Disclosure: Saudi Credit Bureau, SIMAH, a Closed Joint Stock Company, Capital SR 200,000,000 Paid in Full- C.R 1010171047- Membership No.115731, Toll Free No. 8003010046, Fax No. 966112188797+  P.O Box 8859 Riyadh 11492- National Address: Riyadh, Al Shuhada, Building No. 2596, Unit No. 1, Additional No. 7347, Zip Code 13241. Under the Supervision and Regulation of SAMA with a License No. 2 / 37. www.simah.com"
              )}
            </>
          )}
        </a>
      </div>
    </div>
  );
}

export default Disclaimer;
