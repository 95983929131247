import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Table from "Components/Table";
function Calculations() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const UserId = queryParams.get("user");
  const getAllUsersEmi = useSelector((state) => state.getUserApplication);
  const loading = useSelector((state) => state.Loading);
  console.log("loading", loading);
  useEffect(() => {
    getAllUsersEmiData();
  }, []);
  function getAllUsersEmiData() {
    dispatch({
      type: "GET_USER_APPLICATION_DATA",
      payload: UserId,
    });
  }
  function DateSet(newDate) {
    var timestamp = parseInt(newDate); // Example timestamp value
    var date = new Date(timestamp);
    return moment(date.toString()).subtract(10, "days").calendar();
  }

  let header = [
    { name: "Id" },
    { name: "First Installment Date" },
    { name: "Last Installment Date" },
    { name: "Monthly Installment" },
    { name: "Due Installment Date" },
    { name: "Months" },
    { name: "Interest Amount Table" },
    { name: "Outstanding Principal" },
    { name: "Principal Amount Table" },
  ];

  function formatNumber(value) {
    if (isNaN(value)) return "0.00";
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return (
    <div className=" w-full pb-10">
      {getAllUsersEmi?.LoanHistory?.length > 0 ? (
        <div className="overflow-x-auto relative  ">
          <Table header={header} styleMain={"mt-0"}>
            <tbody className="dark:text-dark0">
              {getAllUsersEmi?.LoanHistory?.map((v, k) => (
                <tr
                  key={k}
                  className={`border-b   border-gray-100 dark:border-dark1 ${
                    k % 2
                      ? "bg-gray-50 dark:bg-gray-600"
                      : "bg-white dark:bg-gray-700"
                  }`}
                >
                  <td scope="row" className="px-3 py-4">
                    {k + 1}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {DateSet(v?.firstInstallmentDate)}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {DateSet(v?.lastInstallmentDate) || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {formatNumber(v?.monthlyInstallment)}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.dueInstallmentDate || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {v?.month || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {formatNumber(v?.interestAmountTable) || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {formatNumber(v?.totalOutstandingPrincipal) || "NAN"}
                  </td>
                  <td scope="row" className="px-3 py-4">
                    {formatNumber(v?.principalAmountTable) || "NAN"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="  items-center text-center mt-5 text-lg py-5 dark:text-dark0 text-gray-600">
          {getAllUsersEmi?.LoanHistory?.length === 0 && loading ? (
            "Loading ..."
          ) : (
            <a className="text-xl dark:text-dark0">No data found !</a>
          )}
        </div>
      )}
    </div>
  );
}
export default Calculations;
